<template>
  <div class="app_home" >
    <div class="fast_enter">
      <h1 style="padding-top: 30px">快捷入口</h1>
      <div class="enter_list">
        <div class="enter_item enter_item_company" @click="goCompany">
          <p class="enter_item_title">公司信息</p>
          <p class="enter_item_btn">点击进入</p>
          <i class="iconfont iconicon-20  enter_item_icon"></i>
        </div>
        <div class="enter_gap"></div>
        <div class="enter_item enter_item_sale"  @click="goOutEinv">
          <p class="enter_item_title">销项发票导入</p>
          <p class="enter_item_btn">点击进入</p>
          <i class="iconfont iconicon-24  enter_item_icon"></i>
        </div>
        <div class="enter_gap"></div>
        <div class="enter_item enter_item_income" @click="goIncomeEinv">
          <p class="enter_item_title">进项发票导入</p>
          <p class="enter_item_btn">点击进入</p>
          <i class="iconfont iconicon-02  enter_item_icon"></i>
        </div>
        <div class="enter_gap"></div>
        <div class="enter_item enter_item_query_repetition" @click="goDupCheck">
          <p class="enter_item_title">报销查重</p>
          <p class="enter_item_btn">点击进入</p>
          <i class="iconfont iconicon-21  enter_item_icon"></i>
        </div>
      </div>
    </div>
    <div class="report_area">
      <div class="invoice_quantity_statistics">
        <div class="quantity_statistics_head">
          <span class="statistics_title">发票张数统计</span>
          <!-- <span class="statistics_select">
              <i class="iconfont iconicon-23 statistics_select_icon" @click="subMonthForAmount"></i>
              <span class="statistics_select_date">{{ yearForAmount }}年{{monthForAmount}}月</span>
              <i class="iconfont iconicon-25 statistics_select_icon" @click="addMonthForAmount"></i>
          </span> -->
          <!-- 发票张数区域日期选择器 -->
          <div class="block statistics_select_month">
            <el-date-picker v-model="pieceMonth" @change='queryAmountStatistics' format="yyyy年MM月" type="month" placeholder="选择月"> </el-date-picker>
          </div>
          <!--  -->
        </div>
        <div class="quantity_statistics_body" v-loading="queryAmountLoading">
          <div class="quantity_statistics_item">
            <p class="iconfont iconicon-24 sale_icon"></p>
            <el-button type="text" class="quantity" @click="jumpToWrite">{{ outputAmount }}</el-button>
            <p class="desc">销项电票（张）</p>
          </div>
          <div class="quantity_statistics_item">
            <p class="iconfont iconicon-02 income_icon"></p>
            <el-button type="text"  class="quantity" @click="jumpToIncome">{{ incomeAmount }}</el-button>
            <p class="desc">进项电票（张）</p>
          </div>
        </div>
      </div>
      <div class="invoice_values_statistics" v-loading="queryMoneyLoading">
        <div class="values_statistics_head">
          <span class="statistics_title">开票金额统计</span>
          <!-- <span class="statistics_select">
              <i class="iconfont iconicon-23 statistics_select_icon" @click="subMonthForMoney"></i>
              <span class="statistics_select_date">{{ yearForMoney }}年</span>
              <i class="iconfont iconicon-25 statistics_select_icon" @click="addMonthForMoney"></i>
          </span> -->
          <!-- 开票金额区域日期选择器 -->
          <div class="block statistics_select_year">
            <!-- <span class="demonstration">月</span> -->
            <el-date-picker v-model="moneyYear"  @change='queryMoneyStatistics' format="yyyy年" type="year" placeholder="选择年"> </el-date-picker>
          </div>
          <!--  -->
        </div>
        <div class="values_statistics_body">
          <div class="values_statistics_item">

            <!-- <div class="item_body"> -->
              <!-- <barChart :width="'100%'" :height="'100%'" :color="'#40A0FF'" :title="'销项电票'" :unit="'单位 万元'" v-model="outputOption"></barChart> -->
              <bar-echart :width="'100%'" :height="'100%'" :color="'#40A0FF'" :title="'销项电票'" :unit="'单位 万元'" v-model="outputOption" :id='writOff'></bar-echart>
            <!-- </div> -->
          </div>
          <div class="values_statistics_gap"></div>
          <div class="values_statistics_item">
            <!-- <div class="item_body"> -->
              <!-- <barChart :width="'100%'"
                        :height="'100%'"
                        :color="'#35C4B1'"
                        :title="'进项电票'"
                        :unit="'单位 万元'"
                        v-model="incomeOption"></barChart> -->
              <bar-echart :width="'100%'" :height="'100%'" :color="'#35C4B1'" :title="'进项电票'" :unit="'单位 万元'" v-model="incomeOption" :id='income'></bar-echart>

            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import  barChart  from '@/components/barChart'
import  barEchart  from '@/components/barChart/bar'
export default {
  components: { barChart ,barEchart},
  data() {
    return {
      queryAmountLoading:false,
      queryMoneyLoading:false,
      yearForAmount:'',// 发票张数区域，年
      monthForAmount:'',// 发票张数区域，月
      yearForMoney:'', // 发票金额区域，年
      incomeOption :{},
      outputOption :{},
      incomeAmount:0,
      outputAmount:0,
      PYear:'',
      PMonth:'',
      pieceMonth:'',//发票张数区域，年-月
      moneyYear:'',//开票金额区域，年
      writOff:'writOff',
      income:'income',
      dayNum:'',
      companyList:[],
      loading:false
    };
  },
  methods:{
    /* 金额区 */
    queryMoneyStatistics(){
      if(this.moneyYear){
        var date =new Date(this.moneyYear);
      } else {
        var date =new Date();
        this.moneyYear = date.getFullYear().toString()
      }
      let year = date.getFullYear();

      this.queryMoneyLoading=true;
      this.$store.dispatch("GetAmount", {
        companyId: this.getToken('companyId'),
        year,
      }).then(res => {
        this.queryMoneyLoading=false;
        if (res.success) {
          this.incomeOption=res.data[0];
          this.outputOption=res.data[1];
        }
      }).catch(err => {
        this.isLoading = false
      })
    },
    /* 发票张数区 */
    queryAmountStatistics(){
      if(this.pieceMonth){
        var date =new Date(this.pieceMonth);
      } else {
        var date =new Date();
        this.pieceMonth = `${date.getFullYear()}-${date.getMonth()+1}`
      }
      let year = date.getFullYear();
      let month =date.getMonth()+1;
      this.PYear= year;
      this.PMonth= month
      // console.log(year,month,'年与月');
      this.queryAmountLoading=true;
      this.$store.dispatch("GetCount", {
        companyId: this.getToken('companyId'),
        year,
        month,
      }).then(res => {
        this.queryAmountLoading=false;
        if (res.success) {
            for(var i=0;i<res.data.length;i++){
              if(res.data[i].type=='1'){
                this.incomeAmount=res.data[i].count;
              }else{
                this.outputAmount=res.data[i].count;
              }
            }
        } else {

        }
      }).catch(err => {
        this.isLoading = false
      })
      this.dayNumCompute()

    },

    goCompany(){
      this.$router.push({ path: '/install/company' })
    },
    goOutEinv(){
      this.$router.push({ path: '/writeOff/invoiceAccount' })
    },
    goIncomeEinv(){
      this.$router.push({ path: '/income/invoiceAccount' })
    },
    goDupCheck(){
      this.$router.push({ path: '/income/reimbursementCheck' })
    },
    jumpToWrite(){
      this.$router.push({
        path: '/writeOff/invoiceAccount' ,
        query:{
          startDate:this.PYear+'-'+this.PMonth+'-01',
          endDate:this.PYear+'-'+this.PMonth+ '-' + this.dayNum,
          isSearch:true
        }
        })
    },
    jumpToIncome(){
      this.$router.push({
        path: '/income/invoiceAccount',
        query:{
          startDate:this.PYear+'-'+this.PMonth+'-01',
          endDate:this.PYear+'-'+this.PMonth+ '-' + this.dayNum,
          isSearch:true
        }
         })
    },
    dayNumCompute(){
      this.dayNum = this.getDayNumByYearMonth(this.PYear,this.PMonth)
      console.log(this.PYear,this.PMonth,'年月',this.dayNum,'this.dayNum',)
    },
    //根据年月得到天数
getDayNumByYearMonth:function (year,month){
    switch (month) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
            return 31;
            break;
        case 4:
        case 6:
        case 9:
        case 11:
            return 30;
            break;
        case 2:
            return this.isLeapYear(year) ? 29 : 28;
    }
},
//是否是闰年
isLeapYear:function(year){
    if(year/4 == 0 && year/100 != 0){
        return true ;
    } else if (year/400 == 0){
        return true ;
    } else{
        return false ;
    }
},
    getCompanyList() {
      this.loading=false;
      this.$store.dispatch("FindCompanyByUser", {
        userId: this.getToken("userId"),
      }).then((res) => {
         this.loading=true;
        if (res.success) {
          if(res.data.length){
             this.$emit('doChangeMenuDisabled',false);
             this.$emit('doInitAccountList',res.data);
          }else{
            this.$emit('doInitAccountList',[]);
            this.$emit('removeCurrentCompany');
            this.$emit('doChangeMenuDisabled',true);
            this.$router.push({
              path:'/companyAdd',
              query:{
                lastPath:'home',
              }
            })
          }
        } else {
          einvAlert.error("提示", res.msg);
        }
      })
          .catch((err) => {
            this.isLoading = false;
          });
    },
    showAddAgencyCompany(){
      if(this.getToken('agencyCompanyId')){
         this.getCompanyList();
      }else{
        this.$router.push('/agencyCompanyAdd');
      }
    },
    initPage(){
      let _this =this;
      var initCount =0;
      var initId =setInterval(function (){
        initCount++;
        if(_this.getToken('agencyCompanyId')&&_this.getToken('agencyCompanyId')!=''){
          _this.getCompanyList();
          clearInterval(initId);
        }else if(initCount==10){
          clearInterval(initId);
          this.$router.push('/agencyCompanyAdd');
        }
      },100);
    }

  },
  created() {
    this.initPage();
  },
  mounted() {
    let _this=this;
    let companyId =this.getToken('companyId');
    if(!companyId){
      setTimeout(function (){
        // _this.initCurrentDate();
        _this.queryMoneyStatistics();
        _this.queryAmountStatistics();
          },1200
      )
    }else{
      // this.initCurrentDate();
      this.queryMoneyStatistics();
      this.queryAmountStatistics();
    }

  },
  computed:{
    // loading:function (){
    //   return  this.queryAmountLoading||this.queryMoneyLoading;
    // }
  }
}

</script>
<style lang="less" scoped>
.app_home{
  width: 100%;
  height: 98%;
  .fast_enter{
    background: #ffffff;
    width: 100%;
    height: 31%;
    padding: 0px 19px 47px 19px;
    .enter_list{
      width: 100%;
      height: 69%;
      .enter_item{
        float: left;
        height: 100%;
        width: 23.5%;
        color: #ffffff;
        padding: 16px;
        position: relative;
        border-radius: 7px 0px 0px 0px;
        .enter_item_title{
          font-size: 20px;
          font-weight: bold;
        }
        .enter_item_btn{
          font-size: 16px;
        }
        .enter_item_icon{
          position: absolute;
          top:45px;
          right: 28px;
          font-size: 46px;
        }
      }
      .enter_item:hover{
        cursor: pointer;
        opacity:0.8;
      }
      .enter_item_company{
        background:url("~@/assets/home/company_background.png") no-repeat;
      }
      .enter_item_sale{
        background:url("~@/assets/home/einv_import_bakcground1.png") no-repeat;
      }
      .enter_item_income{
        background:url("~@/assets/home/einv_import_bakcground2.png") no-repeat;
      }
      .enter_item_query_repetition{
        background:url("~@/assets/home/query_repetition_background.png") no-repeat;
      }
      .enter_gap{
        height: 100%;
        width: 2%;
        float: left;
      }
    }
  }
  .report_area{
    width: 100%;
    height: 50%;
    margin-top: 20px;


    .invoice_quantity_statistics{
      width: 30%;
      height: 100%;
      float: left;
      position: relative;
      background: #ffffff;
      padding: 20px;

    }
    .invoice_values_statistics{
      width: 68%;
      height: 100%;
      float: right;
      position: relative;
      background: #ffffff;
      padding: 20px;
      .values_statistics_head{
        width: 100%;
        height: 10%;
        margin-bottom: 8px;
      }
      .values_statistics_body{
        width: 100%;
        height: 80%;
        .values_statistics_item{
          display: inline-block;
          width: 48%;
          height: 100%;
          .item_head{
            height: 15%;
            .item_head_name{
              float: left;
              font-size: 16px;
              color: #333333;
            }
            .item_head_unit{
              float: right;
              font-size: 14px;
              color: #666666
            }
          }
          // .item_body{
          //   width: 100%;
          //   height: 100%;
          //   background-color: rgb(196, 161, 161);
          //   // background-color: rgb(255, 255, 255);
          // }
        }
        .values_statistics_gap{
          display: inline-block;
          width: 3%;
          height: 100%;
        }
      }
    }
    .quantity_statistics_head{
      width: 100%;
      height: 20%;
    }
    .quantity_statistics_body{
      width: 100%;
      height: 70%;
      text-align: center;
      .quantity_statistics_item{
        display: inline-block;
        width: 50%;
        height: 100%;
        .sale_icon{
          font-size: 40px;
          color: #409EFF;
          margin-bottom: 28px;
        }
        .income_icon{
          font-size: 40px;
          color: #35C4B1;
          margin-bottom: 28px;
        }
        .quantity{
          font-size: 26px;
          font-weight: bold;
          color: #333333;
          margin: 0;
        }
        .desc{
          font-size: 16px;
          font-weight: bold;
          color: #515E76;
          margin-top: 10px;
        }
      }
    }
    .statistics_title{
      float: left;
      font-size: 18px;
      color: #333333;
      font-weight: bold;
    }
    /deep/.statistics_select_month{
      float: right;
      font-size: 16px;
      color: #333333;
      width: 170px;
      .el-date-editor {
        width: 170px;
      }
    }
    /deep/.statistics_select_year{
      float: right;
      font-size: 16px;
      color: #333333;
      width: 132px;
      .el-date-editor {
        width: 132px;
      }
    }

      // .statistics_select_icon{
      //   font-size: 20px;
      //   color: #555555;
      //   position: relative;
      //   top: 2px;
      // }
      // .statistics_select_icon:hover{
      //   cursor: pointer;
      //   opacity:0.8;
      // }
      // .statistics_select_date{
      //   margin: 0 10px;
      // }


  }
}


</style>
