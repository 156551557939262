<template>
  <div :style="{'width':width,'height':height,'position':'relative','font-size':font_size}" >
    <div class="item_head">
      <!-- <span class="item_head_name">进项发票</span>
      <span class="item_head_unit">万元</span> -->
      <span class="item_head_name">{{ title }}</span>
      <span class="item_head_unit">{{unit}}</span>
    </div>
    <div :id="id" class="echart"></div>


  </div>

</template>

<script>
import * as echarts from 'echarts/core';
import {
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BrushComponent,
  TitleComponent
} from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BrushComponent,
  TitleComponent,
  BarChart,
  CanvasRenderer
]);
export default {
  name: 'barChart',
  components:{ },
  props:{
    value:{
      type: Object
    },
    width: {
      type: String,
      default: "400px"
    },
    height: {
      type: String,
      default: "300px"
    },
    color:{
      type: String,
      default: "#40A0FF"
    },
    font_size:{
      type: String,
      default: "6px"
    },
    title:{
      type: String,
      default: ""
    },
    unit:{
      type: String,
      default: ""
    },
    id:String
  },
  data () {
    return {
        option:this.value,
        
    }
  },
  methods:{
    initEacherts() {
        console.log('传过来的option',this.option.data_list instanceof Array,this.option.data_list);
        var chartDom = document.getElementById(this.id);
        var myChart = echarts.init(chartDom);
        var option;

        let xAxisData = [];
        let data1 = [];

            // xAxisData=['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月']

        // for (let i = 0; i < 11; i++) {
        // data1.push(+(Math.random() * 2).toFixed(2));
        // }
        // data1.push();
        option = {
            color:this.color,
            tooltip: {},
            xAxis: {
                data: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
                // name: '月',
                axisLine: { onZero: true },
                splitLine: { show: false },
                splitArea: { show: false },
                axisLabel: {
                // interval:0,
                // rotate:45, //代表逆时针旋转45度
                    textStyle: {
                        color: '#333',  //更改坐标轴文字颜色
                        fontSize : 9.8    //更改坐标轴文字大小
                    }
                }
            },
            yAxis: {},
            grid: {
                left:10,
                right: 25,
                bottom: 16,
                top: 30,
                containLabel: true
            },
            series: [
                {
                name: 'bar',
                type: 'bar',
                stack: 'one',
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowColor: 'rgba(0,0,0,0.3)'
                    },
                },
                data: this.option.data_list,
                label: {
                    show: true, //开启显示
                    position: 'top', //在上方显示
                    textStyle: { //数值样式
                        color: '#333',
                        fontSize: 10,
                        position: 'top',
                    },
                    normal:{
                        show:true,
                        position: 'top',
                        fontSize: 10,
                        formatter: function (params) {
                        if (params.value !=0) {
                        return params.value;
                        } else {
                        return '';
                        }
                    },

                    }
                }
                // data: [1,-0.3,0.3,-0.6,-0.3,0.7,-0.3,0.5,-0.3,0.5,0.8,-1,]
                }
            ],
        }
        myChart.on('brushSelected', function (params) {
             var brushed = [];
             var brushComponent = params.batch[0];
             if(brushComponent){
                   for (var sIdx = 0; sIdx < brushComponent.selected.length; sIdx++) {
                     var rawIndices = brushComponent.selected[sIdx].dataIndex;
                     brushed.push('[Series ' + sIdx + '] ' + rawIndices.join(', '));
                   }
                 myChart.setOption({
                     title: {
                     backgroundColor: '#333',
                     text: 'SELECTED DATA INDICES: \n' + brushed.join('\n'),
                     bottom: 0,
                     right: '10%',
                     width: 100,
                     textStyle: {
                         fontSize: 10,
                         color: '#fff'
                     }
                     }
                 });
             }
        });

        option && myChart.setOption(option);
        setTimeout(function (){
            window.addEventListener('resize', function () {
            myChart.resize()
            })
        },200)
        
    }
  },
  mounted() {
      this.initEacherts()
  },
  watch:{
    value: function(newValue,oldValue){
      this.option=newValue;
      this.initEacherts();
    },
  }
}
</script>

<style scoped lang="less">
.item_head{
  height: 15%;
  .item_head_name{
    float: left;
    font-size: 16px;
    color: #333333;
  }
  .item_head_unit{
    float: right;
    font-size: 14px;
    color: #666666;
    margin-right: 26px;
  }
}
.echart {
    width: 100%;
    height: 300px;
}
</style>
