<template>
  <div class="root_div" :style="{'width':width,'height':height,'position':'relative','font-size':font_size}" >
    <div class="item_head">
      <span class="item_head_name">{{ title }}</span>
      <span class="item_head_unit">{{unit}}</span>
    </div>
    <div style="height: 80%;width: 100%;">
      <div :style="{'text-align': 'center','width': '7%','height': '100%','display': 'inline-block'}">
        <div class="y_percent" :style="{'bottom': bottom}" v-for="(item,index) in y_list" :key="index">{{ item }}</div>
      </div>
      <div style="width: 92%;height: 100%;display: inline-block;position: relative; border-bottom: 1px solid #D1D1D1">
        <div class="dotted_line" v-for="index  of 5" :key="index"></div>
        <div  :style="{'width':'8.3%','height': item.height+'%','z-index': '100','position': 'absolute','bottom': '0px','left': index*8.3+'%'}"
              v-for="(item,index) in dataHeight_List" >
          <div :style="{'text-align': 'center','float': 'left','position': 'relative','bottom':bottom2,'width': '100%'}">
            {{ item.data }}</div>
          <div :style="{'text-align': 'center','background-color': color,'margin':'0 25%', 'width': '50%','height': '100%'}"></div>
        </div>
      </div>
    </div>
    <div style="height: 10%;width: 100% ;">
      <div :style="{'text-align': 'center','width': '7%','height': '100%','display': 'inline-block'}">
        <div class="y_percent" :style="{'bottom': bottom}">0</div>
      </div>
      <div style="width: 92%;height: 100%;display: inline-block;position: relative">
        <div  :style="{'width':'8.3%','height': '100%','z-index': '100','position': 'absolute','bottom': '-3px','left': index*8.3+'%'}" v-for="(item,index) in option.x_list" :key="index" >
          <div :style="{'text-align': 'center','margin':'0 10%', 'width': '80%','height': '100%'}">{{item}}</div>
          </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'barChart',
  props:{
    value:{
      type: Object
    },
    width: {
      type: String,
      default: "400px"
    },
    height: {
      type: String,
      default: "300px"
    },
    color:{
      type: String,
      default: "#40A0FF"
    },
    font_size:{
      type: String,
      default: "6px"
    },
   /* option:{
      type: Object,
      default:function (){
        return {

        }
      }
    },*/
    title:{
      type: String,
      default: ""
    },
    unit:{
      type: String,
      default: ""
    }
  },
  data () {
    return {
      bottom:((new Number(this.font_size.substr(0,this.font_size.length-2)))/2+3)+'px',
      //柱状图上字的高度
      bottom2:(new Number(this.font_size.substr(0,this.font_size.length-2))<15?15:new Number(this.font_size.substr(0,this.font_size.length-2)))+'px',
      y_list:[],
      dataHeight_List:[],
      option:this.value
    }
  },
  methods:{
    //获取集合的最大值
    getMax(data){
      if(data==null){
        return 0;
      };
      var biggerOneIndex  =0;
      for(var i=1;i<data.length;i++){
        if(data[biggerOneIndex]<data[i]){
          biggerOneIndex=i;
        }
      }
      return data[biggerOneIndex];
    },
    //获取一个数所在的数量级
    getMagnitudes(num){
      var biggerOneStr = num.toString();
      var isReplaceIndex =false;
      var biggerOneStrArray =biggerOneStr.split('');
      var biggerOneStrTemp='';
      var ischeckd =false;
      for(var j=0;j<biggerOneStrArray.length;j++){
        var char =biggerOneStrArray[j];
        if(!isReplaceIndex&&char!='0'&&char!='.'){
          biggerOneStrTemp=biggerOneStrTemp+'1';
          isReplaceIndex=true;
          if(ischeckd){
            break
          }
        }else if(isReplaceIndex&&char!='0'&&char!='.'){
          biggerOneStrTemp=biggerOneStrTemp+'0';
          if(isReplaceIndex&&ischeckd){
            break;
          }
        }else if(biggerOneStrArray[j]=='.'){
          biggerOneStrTemp=biggerOneStrTemp+'.';
          ischeckd=true;
        }else if(biggerOneStrArray[j]=='0'){
          biggerOneStrTemp=biggerOneStrTemp+'0';
        }
      }
      return biggerOneStrTemp;
    },
    //去除小数后面多余的零
    cutZero(old){
      //拷贝一份 返回去掉零的新串
      var newstr=old;
      //循环变量 小数部分长度
      var leng = old.length-old.indexOf(".")-1
      //判断是否有效数
      if(old.indexOf(".")>-1){
        //循环小数部分
        for(var i=leng;i>0;i--){
          //如果newstr末尾有0
          if(newstr.lastIndexOf("0")>-1 && newstr.substr(newstr.length-1,1)==0){
            var k = newstr.lastIndexOf("0");
            //如果小数点后只有一个0 去掉小数点
            if(newstr.charAt(k-1)=="."){
              return  newstr.substring(0,k-1);
            }else{
              //否则 去掉一个0
              newstr=newstr.substring(0,k);
            }
          }else{
            //如果末尾没有0
            return newstr;
          }
        }
      }
      return old;
    },
    inity_list() {
     var max =this.getMax(this.option.data_list);
     if(max===0){
       this.y_list=[1,0.08,0.06,0.04,0.02];
       this.dataHeight_List=[{'data':0,'height':0},{'data':0,'height':0},{'data':0,'height':0},{'data':0,'height':0},{'data':0,'height':0}, {'data':0,'height':0},
                             {'data':0,'height':0},{'data':0,'height':0},{'data':0,'height':0},{'data':0,'height':0},{'data':0,'height':0},{'data':0,'height':0}];
       return;
     }
     var biggerOneStrTemp= this.getMagnitudes(max);
     var x_list_bigger =0;
     x_list_bigger =new Number(biggerOneStrTemp);
      this.y_list=[];
     for(var l=5;l>=1;l--){
       this.y_list.push(this.cutZero((x_list_bigger*l*2).toFixed(4)));
     }
     this.dataHeight_List=[];
     for(var i=0;i<this.option.data_list.length;i++){
       var height =(this.option.data_list[i]/x_list_bigger*10).toFixed(4);
       var data ={};
       data.data=this.option.data_list[i];
       data.height=height;
       this.dataHeight_List.push(data);
     }
    },

  },
  mounted() {
    this.inity_list();
  },
  watch:{
    value: function(newValue,oldValue){
      this.option=newValue;
      this.inity_list();
    },
  }
}
</script>

<style scoped lang="less">
.root_div{

}
.dotted_line{
  height: 20%;
  border-top: 1px  dashed #D1D1D1;
  width: 100%;
  float: right;
}
.y_percent{
  margin-top: 1px;
  height: 20%;
  width: 100%;
  float: right;
  position: relative;
  text-align: right;
  margin-right: 10%;
}
.item_head{
  height: 15%;
  .item_head_name{
    float: left;
    font-size: 16px;
    color: #333333;
  }
  .item_head_unit{
    float: right;
    font-size: 14px;
    color: #666666
  }
}
</style>
